import { Children, cloneElement, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { cn, Collapse, RawHtml } from '@divlab/divanui';

import styles from './Group.module.css';

import type { FC, HTMLAttributes, ReactElement } from 'react';

export interface GroupProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  theme: string;
  title?: string;
  unit?: string;
  selectedText?: string;
  defaultCollapsed?: boolean;
  outerCollapsed?: boolean;
}

const Group: FC<GroupProps> = (props) => {
  const {
    className,
    theme,
    title,
    unit,
    selectedText,
    defaultCollapsed,
    outerCollapsed,
    children,
    ...restProps
  } = props;
  const [collapsed, setCollapsed] = useState(defaultCollapsed);

  const handleClickHead = useCallback(() => {
    setCollapsed((prev) => !prev);
  }, []);

  const fullTitle = useMemo(() => {
    return [title, unit].filter(Boolean).join(', ');
  }, [title, unit]);

  useEffect(() => {
    if (outerCollapsed) {
      setCollapsed(outerCollapsed);
    }
  }, [outerCollapsed]);

  return (
    <div
      {...restProps}
      className={cn(
        styles.group,
        { [styles.collapsed]: collapsed, [styles.withoutTitle]: !fullTitle },
        className,
      )}
    >
      {fullTitle && (
        <div className={styles.head} onClick={handleClickHead}>
          <div className={styles.title}>
            <RawHtml html={fullTitle} />
          </div>

          <div className={styles.right}>
            {selectedText && collapsed && (
              <div className={styles.selectedText}>
                <RawHtml html={selectedText} />
              </div>
            )}
            <div className={styles.arrow} />
          </div>
        </div>
      )}

      <Collapse collapsed={collapsed} key={`${Children.count(children)}_${title}`}>
        <div className={styles.content}>
          {Children.map(children, (child: ReactElement, index) => {
            const childProps = { ...child.props };

            if (theme === 'checkbox') {
              childProps.collapsedParent = collapsed;
            }

            return (
              <div className={styles.item} key={index}>
                {cloneElement(child, childProps)}
              </div>
            );
          })}
        </div>
      </Collapse>
    </div>
  );
};

export default memo(Group);
