import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { cn, InputsRange, Switch } from '@divlab/divanui';

import * as Filtrator from '@Stores/Filtrator';
import Group from '../Group';
import GroupItem from '../GroupItem';
import CheckboxGroup from '../CheckboxGroup';
import useTranslation from '@Queries/useTranslation';
import styles from './Content.module.css';

import type { FC, HTMLAttributes, ChangeEvent } from 'react';
import type { CheckboxItemData } from '@Components/CheckboxList/typings';
import type { FiltersGroup, FiltersParameter } from '@Types/Filters';

export interface ContentProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  entityId: 'catalog' | 'textile' | 'constructor';
  selectedFilterId?: string;
  onChangeFilters?: () => Promise<void>;
}

const formatName = (name: string) => name.replace(/ /g, '');

// List of themes that should not render in the modal windows
const ignoredThemes: FiltersGroup['theme'][] = ['rubric-checkboxes'];

const Content: FC<ContentProps> = (props) => {
  const { className, entityId, selectedFilterId, onChangeFilters, ...restProps } = props;
  const filtrator = Filtrator.useFiltrator(entityId);
  const selected = Filtrator.useSelected(entityId);
  const [isDisable, setIsDisable] = useState(false);
  const [isRangeDragging, setIsRangeDragging] = useState(false);
  const refRange = useRef(null);
  const { t } = useTranslation();

  const handleChangeFilters = useCallback(async () => {
    if (!onChangeFilters) return;

    setIsDisable(true);
    await onChangeFilters();
    setIsDisable(false);
  }, [onChangeFilters]);

  const handleChangeRange = useCallback(
    (_e: ChangeEvent<HTMLInputElement>, params) => {
      const from = params.type === 'min' ? params.value : null;
      const to = params.type === 'max' ? params.value : null;
      const opts = { id: params.parameterId, from, to };

      Filtrator.changeRange(entityId, opts);

      setIsRangeDragging(true);
    },
    [entityId, setIsRangeDragging],
  );

  const handleToggleSwitch = useCallback(
    (_e, item: CheckboxItemData) => {
      const opts = { id: item.data.parameterId, value: item.data.value };

      if (item.checked) {
        Filtrator.removeCheckbox(entityId, opts);
      } else {
        Filtrator.addCheckbox(entityId, opts);
      }
      handleChangeFilters();
    },
    [entityId, handleChangeFilters],
  );

  const handleChangeCheckbox = useCallback(() => {
    handleChangeFilters();
  }, [handleChangeFilters]);

  const createTitle = useCallback((parameter: FiltersParameter) => {
    return [parameter.name, parameter.unit].join(', ');
  }, []);

  const handleMouseUp = useCallback(() => {
    if (isRangeDragging) {
      setIsRangeDragging(false);
      handleChangeFilters();
    }
  }, [isRangeDragging, handleChangeFilters, setIsRangeDragging]);

  useEffect(() => {
    window.addEventListener('mouseup', handleMouseUp);
    window.addEventListener('touchend', handleMouseUp);

    return () => {
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('touchend', handleMouseUp);
    };
  }, [handleMouseUp]);

  return (
    <div {...restProps} className={cn(styles.content, className)}>
      {filtrator.filters.map((filter, indexFilter) => {
        if (ignoredThemes.includes(filter.theme)) return;

        if (filter.theme === 'switch') {
          return filter.items.map((item, indexItem) => {
            const parameter = filtrator.parameters[item.parameterId];
            const data = filtrator.parameterValues.find(
              (val) => val.parameterId === item.parameterId,
            );
            const val = {
              id: indexItem,
              text: parameter.name,
              checked: !!parameter.default,
              data,
            };

            return (
              item.theme === 'checkbox' && (
                <Group
                  className={cn(styles.group, { [styles.disable]: isDisable })}
                  theme={filter.theme}
                  key={indexItem}
                  id={formatName(filter.name)}
                  defaultCollapsed={false}
                >
                  <div className={styles.switchGroup}>
                    <Switch
                      className={styles.switch}
                      name={item.parameterId}
                      checked={!!parameter.default}
                      onChange={(e) => handleToggleSwitch(e, val)}
                      disabled={isDisable}
                    />
                    {parameter.name}
                  </div>
                </Group>
              )
            );
          });
        }

        const selectedText = [];
        let groupFilterItems = [];
        const [firstItem] = filter.items;
        const firstParameter = filtrator.parameters[firstItem.parameterId];
        const defaultCollapsed =
          selectedFilterId !== 'all' && formatName(selectedFilterId) !== formatName(filter.name);
        const isSelectedFilter = formatName(selectedFilterId) === formatName(filter.name);

        filter.items.forEach((item) => {
          const { parameterId, theme } = item;
          const parameter = selected.parameters[parameterId];

          groupFilterItems = filtrator.parameterValues.filter((pv) => {
            return pv.parameterId === item.parameterId;
          });

          if (!parameter) return;

          switch (theme) {
            case 'checkbox': {
              const names = [];
              parameter.default.forEach((val) => {
                const parameterValue = selected.parameterValues.find((value) => {
                  if (value.type !== 'variant') return;
                  return value.value.includes(val);
                });

                names.push(parameterValue.name);
              });
              selectedText.push(names.join(', '));
              break;
            }

            case 'range': {
              const name = filter.items.length > 1 ? `${parameter.name}: ` : '';

              selectedText.push(`${name}${parameter.default.join(' - ')} ${parameter.unit}`);
              break;
            }

            default:
          }
        });

        const outerCollapsed = groupFilterItems.every((item) => item.count === 0);

        return (
          <Group
            className={cn(styles.group, { [styles.disable]: isDisable })}
            theme={filter.theme}
            title={filter.name}
            unit={firstParameter.unit}
            key={indexFilter}
            id={formatName(filter.name)}
            selectedText={selectedText.join(', ')}
            defaultCollapsed={defaultCollapsed}
            outerCollapsed={outerCollapsed}
          >
            {filter.items.map((item) => {
              const parameter = filtrator.parameters[item.parameterId];
              const values = filtrator.parameterValues.filter((pv) => {
                return pv.parameterId === item.parameterId;
              });
              const [firstValue] = values;
              const title = createTitle(parameter);

              const isUniqParameterName = filter.name !== parameter.name;

              if (item.theme === 'range') {
                return (
                  <GroupItem
                    key={item.parameterId}
                    title={filter.items.length > 1 || isUniqParameterName ? title : undefined}
                  >
                    <div ref={refRange}>
                      <InputsRange
                        min={+firstValue.value[0]}
                        max={+firstValue.value[1]}
                        valueMin={+parameter.default[0]}
                        valueMax={+parameter.default[1]}
                        disabled={firstValue.value[0] === firstValue.value[1]}
                        readOnly={isDisable}
                        labels={{ from: t('ui.range.from'), to: t('ui.range.to') }}
                        onChange={(e, params) => {
                          return handleChangeRange(e, {
                            parameterId: item.parameterId,
                            ...params,
                          });
                        }}
                      />
                    </div>
                  </GroupItem>
                );
              }

              if (item.theme === 'checkbox') {
                return (
                  <CheckboxGroup
                    key={item.parameterId}
                    items={values}
                    entityId={entityId}
                    parameter={parameter}
                    isSelectedFilter={isSelectedFilter}
                    onChange={handleChangeCheckbox}
                    className={styles.checkboxGroup}
                  />
                );
              }
            })}
          </Group>
        );
      })}
    </div>
  );
};

export default memo(Content);
