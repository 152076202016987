import { memo, useCallback, useRef, useState } from 'react';
import { cn } from '@divlab/divanui';
import { IconSuccess, IconBoldCross } from '@divlab/divanui/icons';

import * as VoiceOfCustomersStore from '@Stores/VoiceOfCustomers';
import { useDeps } from '@Contexts/DI';
import useModalWindowTranslation from '@Queries/useModalWindowTranslation';
import InputPlaceholder from '@UI/InputPlaceholder';
import Button from '@UI/Button';
import styles from './VoiceOfCustomers.module.css';

import type { FC, ChangeEvent } from 'react';
import type { QuizData } from '@Stores/VoiceOfCustomers';
import type { FilterView } from '@Types/Filters';

type StepState = 'initial' | 'message' | 'success';

interface VoiceOfCustomersProps {
  quiz: QuizData;
  selectedFilterId?: string;
  filterView?: FilterView;
}

const VoiceOfCustomers: FC<VoiceOfCustomersProps> = (props) => {
  const { quiz, selectedFilterId, filterView } = props;
  const [step, setStep] = useState<StepState>('initial');
  const [loading, setLoading] = useState(false);
  const { t } = useModalWindowTranslation({ modalId: 'Filters' });
  const { submit } = VoiceOfCustomersStore.useSubmit(quiz.name);
  const initStepRef = useRef(null);
  const successStepRef = useRef(null);
  const [wrapperHeight, setWrapperHeight] = useState<number>();
  const { analytics } = useDeps();
  const isViewSticky = filterView === 'sticky';

  const wrapperStyles = {
    height: wrapperHeight,
  } as React.CSSProperties;

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    if (quiz.result?.message.length >= 3) {
      await submit();
      setStep('success');
      setWrapperHeight(successStepRef.current?.clientHeight);
      VoiceOfCustomersStore.setResult({ message: '' }, quiz.name);
      analytics.dispatchEvent('filters.form', {
        label: selectedFilterId === 'all' ? 'Все фильтры' : selectedFilterId,
        status: isViewSticky ? 'закреп' : 'не закреп',
        action: 'send',
      });
    } else {
      VoiceOfCustomersStore.setError(true, quiz.name);
    }
    setLoading(false);
  }, [analytics, isViewSticky, quiz.name, quiz.result?.message.length, selectedFilterId, submit]);

  const handleClick = useCallback(() => {
    setStep('message');

    analytics.dispatchEvent('filters.form', {
      label: selectedFilterId === 'all' ? 'Все фильтры' : selectedFilterId,
      status: isViewSticky ? 'закреп' : 'не закреп',
      action: 'click',
    });
  }, [isViewSticky, selectedFilterId, analytics]);

  const handleClose = useCallback(() => {
    VoiceOfCustomersStore.setError(false, quiz.name);
    setStep('initial');
    setWrapperHeight(initStepRef.current?.clientHeight);
  }, [quiz.name]);

  const handleReset = useCallback(() => {
    VoiceOfCustomersStore.setError(false, quiz.name);
    VoiceOfCustomersStore.setResult({ message: '' }, quiz.name);
  }, [quiz.name]);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      VoiceOfCustomersStore.setError(false, quiz.name);
      VoiceOfCustomersStore.setResult({ message: e.target.value }, quiz.name);
    },
    [quiz.name],
  );

  return (
    <div className={styles.container} style={wrapperStyles}>
      <div
        className={cn(styles.step, {
          [styles.visibleInitial]: step === 'initial',
        })}
        ref={initStepRef}
      >
        <div className={styles.title}>{quiz.form.title}</div>
        <div className={styles.text}>
          <span>{t('voice-of-customers.write-need-filter')}</span>
        </div>

        <Button className={styles.stepButton} theme='transparent' onClick={handleClick}>
          {t('voice-of-customers.tell')}
        </Button>
      </div>
      <div
        className={cn(styles.step, {
          [styles.visibleMessage]: step === 'message',
        })}
      >
        <div className={styles.titleWrapper}>
          <div className={styles.title}>{quiz.form.title}</div>
          <div className={styles.cross} onClick={handleClose}>
            <IconBoldCross className={styles.icon} />
          </div>
        </div>
        <InputPlaceholder
          placeholder={t('voice-of-customers.what-filter-need')}
          theme='secondary'
          cnControl={styles.cnControl}
          onChange={handleChange}
          onReset={handleReset}
          error={quiz.error && t('voice-of-customers.message-required')}
        />
        <Button waiting={loading} size='m' wide theme='transparent' onClick={handleSubmit}>
          {t('voice-of-customers.send')}
        </Button>
      </div>
      <div
        className={cn(styles.success, {
          [styles.visible]: step === 'success',
        })}
        ref={successStepRef}
      >
        <IconSuccess className={styles.iconSuccess} />
        <div className={styles.title}>{quiz?.form.thankYou}</div>
      </div>
    </div>
  );
};

export default memo(VoiceOfCustomers);
