import { useQuery } from '@tanstack/react-query';

import * as ApiMeta from '@Api/Meta';
import { useRequest } from '@Contexts/Request';
import { getPluralForm } from './useTranslation';

import type { ModalId } from '@Stores/ModalWindows/typings';

interface UseTranslationParams {
  modalId: ModalId;
}

const useModalWindowTranslation = (params: UseTranslationParams) => {
  const { country, language } = useRequest();
  const queryParams = { lang: language.id, country, modalId: params.modalId };

  const translation = useQuery({
    queryKey: ['translation', 'modal-window', queryParams],
    queryFn: () => {
      return ApiMeta.getModalWindowTranslation(queryParams);
    },
    enabled: Boolean(params?.modalId),
    placeholderData: (previousData) => previousData,
  });

  function ref(row: any, key: string): string | undefined {
    key.split('.').forEach((k) => (row ? (row = row[k]) : undefined));

    return row;
  }

  function t(key: string, count?: number) {
    let modifiedKey: string;
    if (count !== undefined) {
      const pluralForm = getPluralForm(count, language.id);
      modifiedKey = `${key}.${pluralForm}`;
    } else {
      modifiedKey = key;
    }
    const value = ref(translation.data, modifiedKey);

    return value === '' ? '' : value || modifiedKey;
  }

  return { t, isPending: translation.isPending, isError: translation.isError };
};

export default useModalWindowTranslation;
