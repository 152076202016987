import { memo, useCallback } from 'react';
import { cn } from '@divlab/divanui';
import { IconCheck } from '@divlab/divanui/icons';

import styles from './Row.module.css';

import type { FC, HTMLAttributes, MouseEvent } from 'react';
import type { CheckboxItemData } from '../../typings';

export interface CheckboxRowProps extends Omit<HTMLAttributes<HTMLElement>, 'onToggle'> {
  className?: string;
  item: CheckboxItemData;
  onToggle?: (e: MouseEvent, item: CheckboxItemData) => void;
}

const CheckboxRow: FC<CheckboxRowProps> = (props) => {
  const { className, item, onToggle, ...restProps } = props;
  const isWhite = ['#ffffff', '#fff', 'white'].includes(item.color?.toLowerCase());

  const handleClick = useCallback(
    (e: MouseEvent) => {
      if (item.disabled) return;

      if (onToggle) onToggle(e, item);
    },
    [item, onToggle],
  );

  return (
    <div
      {...restProps}
      className={cn(styles.row, { [styles.colored]: !!item.color }, className)}
      onClick={handleClick}
    >
      <input
        readOnly
        className={cn(styles.control)}
        type='checkbox'
        checked={item.checked}
        disabled={item.disabled}
      />

      <div className={cn(styles.container, { [styles.disabled]: item.disabled })}>
        <div
          tabIndex={0}
          className={cn(styles.box, {
            [styles.isWhite]: isWhite,
            [styles.checked]: item.checked,
            [styles.color]: item.color,
          })}
          style={item.color ? { backgroundColor: item.color } : null}
        >
          {item.color && <div className={styles.colorBackground}></div>}
          <div className={styles.check}>
            <IconCheck className={styles.iconCheck} />
          </div>
        </div>

        <span className={styles.text}>{item.text}</span>
      </div>
    </div>
  );
};

export default memo(CheckboxRow);
