import { memo, useState, useCallback, useEffect } from 'react';
import { cn, List } from '@divlab/divanui';

import Row from './elements/Row';
import styles from './CheckboxList.module.css';

import type { FC, HTMLAttributes, MouseEvent } from 'react';
import type { CheckboxItemData } from './typings';

export interface CheckboxListProps extends Omit<HTMLAttributes<HTMLElement>, 'onToggle'> {
  className?: string;
  items: CheckboxItemData[];
  mode?: 'single' | 'single-required' | 'multiple';
  onToggle?: (e: MouseEvent, item: CheckboxItemData) => void;
}

const CheckboxList: FC<CheckboxListProps> = (props) => {
  const { className, items, mode = 'multiple', onToggle, ...restProps } = props;
  const [innerItems, setInnerItems] = useState(() => {
    const result = [...items];

    const hasCheckedPosition = result.findIndex((position) => position.checked);
    if (mode === 'single-required' && hasCheckedPosition < 0) {
      result[0].checked = true;
    }

    return result;
  });

  const handleToggle = useCallback(
    (e: MouseEvent, item: CheckboxItemData) => {
      switch (mode) {
        case 'multiple':
          setInnerItems((prev) => {
            return prev.map((prevItem) => ({
              ...prevItem,
              checked: prevItem.id === item.id ? !prevItem.checked : prevItem.checked,
            }));
          });
          break;

        case 'single':
          setInnerItems((prev) => {
            const newState = [...prev];
            const checkedPosition = newState.findIndex((position) => position.checked);
            if (checkedPosition >= 0 && checkedPosition !== item.id) {
              newState[checkedPosition].checked = false;
            }
            newState[item.id].checked = !newState[item.id].checked;
            return newState;
          });
          break;

        case 'single-required':
          setInnerItems((prev) => {
            const newState = [...prev];
            const checkedPosition = newState.findIndex((position) => position.checked);
            if (checkedPosition >= 0) {
              newState[checkedPosition].checked = false;
            }
            newState[item.id].checked = !newState[item.id].checked;
            return newState;
          });
          break;

        default:
          break;
      }

      if (onToggle) onToggle(e, item);
    },
    [mode, onToggle],
  );

  useEffect(() => {
    const result = [...items];

    const hasCheckedPosition = result.findIndex((position) => position.checked);
    if (mode === 'single-required' && hasCheckedPosition < 0) {
      result[0].checked = true;
    }

    setInnerItems(result);
  }, [items, mode]);

  return (
    <List
      {...restProps}
      className={cn(styles.list, className)}
      items={innerItems}
      renderChild={(item: CheckboxItemData) => {
        return <Row className={styles.row} item={item} onToggle={handleToggle} />;
      }}
    />
  );
};

export default memo(CheckboxList);
