import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { cn, Collapse } from '@divlab/divanui';

import * as Filtrator from '@Stores/Filtrator';
import CheckboxList from '@Components/CheckboxList';
import useModalWindowTranslation from '@Queries/useModalWindowTranslation';
import styles from './CheckboxGroup.module.css';

import type { FC, FormEvent, HTMLAttributes } from 'react';
import type { FiltersParameter, FiltersValue } from '@Types/Filters';
import type { CheckboxItemData } from '@Components/CheckboxList/typings';

export interface CheckboxGroupProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  items: FiltersValue[];
  entityId: 'catalog' | 'textile' | 'constructor';
  parameter: FiltersParameter;
  collapsedParent?: boolean;
  isSelectedFilter?: boolean;
  onChange?: (e: FormEvent) => void;
}

const CheckboxGroup: FC<CheckboxGroupProps> = (props) => {
  const {
    className,
    entityId,
    items,
    parameter,
    collapsedParent,
    isSelectedFilter,
    onChange,
    ...restProps
  } = props;
  const { t } = useModalWindowTranslation({ modalId: 'Filters' });
  const [collapsed, setCollapsed] = useState(!isSelectedFilter);

  const checkboxItems = useMemo(() => {
    const listItems = items.map((value, index) => ({
      id: index,
      checked: parameter.default.includes(value.value[0]),
      text: value.name,
      color: value.meta && value.meta.color,
      data: value,
      disabled: value?.count === 0,
    }));
    if (listItems.length <= 5) {
      return { main: listItems, secondary: [] };
    }

    return { main: listItems.slice(0, 4), secondary: listItems.slice(4) };
  }, [items, parameter.default]);

  const secondaryState = useMemo(() => {
    return checkboxItems.secondary.every((item) => item.checked) ? 'checked' : 'mixed';
  }, [checkboxItems.secondary]);

  const handleToggleCheckbox = useCallback(
    (e: FormEvent, item: CheckboxItemData) => {
      const opts = { id: item.data.parameterId, value: item.data.value[0] };

      if (item.checked) {
        Filtrator.removeCheckbox(entityId, opts);
      } else {
        Filtrator.addCheckbox(entityId, opts);
      }
      if (onChange) {
        setTimeout(() => {
          onChange(e);
        });
      }
    },
    [entityId, onChange],
  );

  const handleToggleMore = useCallback(() => {
    if (secondaryState === 'checked') return;
    setCollapsed((prev) => !prev);
  }, [secondaryState]);

  useEffect(() => {
    if (collapsedParent) {
      setCollapsed(true);
    }
  }, [collapsedParent]);

  return (
    <div {...restProps} className={className}>
      <CheckboxList items={checkboxItems.main} onToggle={handleToggleCheckbox} />
      {checkboxItems.secondary.length > 0 && (
        <div className={cn(styles.secondary, { [styles.collapsed]: !collapsed })}>
          {secondaryState === 'checked' && (
            <Collapse className={styles.checked} collapsed={false}>
              <CheckboxList items={checkboxItems.secondary} onToggle={handleToggleCheckbox} />
            </Collapse>
          )}

          {secondaryState === 'mixed' &&
            checkboxItems.secondary.map((item, index) => {
              return (
                <div key={index} className={cn(styles.mixed, { [styles.checked]: item.checked })}>
                  <Collapse collapsed={collapsed && !item.checked} duration={200}>
                    <CheckboxList items={[item]} onToggle={handleToggleCheckbox} />
                  </Collapse>
                </div>
              );
            })}
          <div
            onClick={handleToggleMore}
            className={cn(styles.showMore, { [styles.disabled]: secondaryState === 'checked' })}
          >
            <span>{collapsed ? t('show-all') : t('collapse')}</span>
            <div className={styles.icon}></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(CheckboxGroup);
